@use 'src/themes/variables' as *;

.Home {
  &__header {
    margin-bottom: 24px;
    background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    .BaseText {
      &:first-child {
        font-size: 24px;
        margin-bottom: 8px;
        color: $font-color-default;
      }

      &:last-child {
        color: $font-color-disable;
      }
    }

    &-loading {
      display: flex;
      justify-content: center;
      height: 42px;
    }
  }
}
