@use 'src/themes/variables' as *;

.RevenueReport {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 24px;

  &__title {
    margin-bottom: 24px;

    &-headline {
      font-size: 16px;
      font-weight: 500;
      color: #292d32;
      margin-bottom: 4px;
    }

    &-time {
      color: $font-color-disable;
      font-size: 14px;
    }
  }

  &__summary {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
    
    &-item {
      flex: 1;
      background: #f8f9fa;
      padding: 16px;
      border-radius: 8px;

      .BaseText {
        &[type="caption"] {
          color: $font-color-disable;
          margin-bottom: 8px;
          font-size: 14px;
        }

        &[type="headline"] {
          font-size: 24px;
          color: #292d32;
          
          &.positive {
            color: #34C759;
          }

          &.negative {
            color: #FF3B30;
          }
        }
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    height: auto;
  }

  &__chart {
    flex: 1;
    background: #f8f9fa;
    border-radius: 8px;
    padding: 24px;
    min-height: 400px;
    display: flex;
    align-items: center;
  }

  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;

    &-filter {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 16px;
    }
  }

  &__filter {
    padding: 10px 16px;
    border-radius: 8px;
    background-color: #f8f9fa;
    border: 1px solid $border-default-color;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 140px;
    justify-content: space-between;
    height: 42px;
    transition: all 0.2s ease;

    .BaseText {
      color: $font-color-disable;
      font-size: 14px;
    }

    &:hover {
      border-color: $color-primary;
    }
  }
}
