.TrainingMaterialActionModal {
  &__input-fields {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    gap: 16px;

    &.delete {
      justify-content: center;
    }
  }

  &__button {
    min-width: 120px;
  }

  .hasRequireMark::before {
    content: '*';
    color: #ff4d4f;
    margin-right: 4px;
  }
} 