.AnalyticsOverview {
  padding: 24px;

  .ant-card {
    height: 100%;
    
    .ant-card-head {
      min-height: 48px;
      padding: 0 16px;
      
      .ant-card-head-title {
        padding: 12px 0;
      }
    }

    .ant-card-body {
      padding: 16px;
    }
  }

  .homework-stat-card {
    .ant-statistic {
      margin-bottom: 16px;
    }

    .progress-wrapper {
      .ant-progress {
        margin: 0;
        line-height: 1;

        .ant-progress-text {
          font-size: 14px;
        }
      }

      .completion-text {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        text-align: right;
        margin-top: 4px;
      }
    }
  }

  .mt-4 {
    margin-top: 24px;
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    padding: 16px;

    .ant-card {
      margin-bottom: 16px;
    }
  }
}

.analytics-overview {
  margin-bottom: 24px;

  .ant-card {
    height: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background: white;
    transition: all 0.3s;

    .ant-card-head {
      min-height: 48px;
      padding: 0 24px;
      border-bottom: 1px solid #e1e6ef;
      
      .ant-card-head-title {
        padding: 16px 0;
        font-size: 16px;
        font-weight: 500;
        color: #292d32;
      }
    }

    .ant-card-body {
      padding: 24px;
    }

    .ant-statistic {
      .ant-statistic-title {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        margin-bottom: 8px;
      }

      .ant-statistic-content {
        font-size: 24px;
        color: #292d32;
      }
    }
  }

  .template-stats-card, .homework-stats-card {
    margin-top: 24px;
  }

  .ant-table {
    .ant-table-thead > tr > th {
      background: #f8f9fa;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }

    .ant-table-tbody > tr > td {
      font-size: 14px;
    }
  }

  .progress-wrapper {
    .ant-progress {
      margin: 0;
      line-height: 1;

      .ant-progress-text {
        font-size: 14px;
      }
    }

    .completion-text {
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
      text-align: right;
      margin-top: 4px;
    }
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    padding: 0;

    .ant-card {
      margin-bottom: 16px;
    }
  }
} 