.TrainingMaterials {
  &__header {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }
  
  &__filters {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  
  &__list {
    margin-top: 24px;
  }
  
  &__empty {
    text-align: center;
    padding: 48px 0;
  }
} 