@use 'src/themes/variables' as *;

.RevenueDetailList {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  
  .content-item {
    flex: 1;
    align-items: center;
  }

  &__row {
    display: flex;
    border-bottom: 1px solid $border-default-color;
    padding: 12px 16px;
    height: 48px;
    align-items: center;

    &:last-child {
      border-bottom: none;
    }

    &.header {
      color: $font-color-disable;
      background-color: #F8F9FA;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      height: 42px;

      .content-row {
        flex: 1;
        align-items: center;
        font-weight: 500;
      }
    }

    &.content {
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #F8F9FA;
      }

      .content-item {
        flex: 1;
        align-items: center;

        &:last-child {
          color: $color-primary;
          font-weight: 500;
        }
      }
    }

    & > :first-child {
      flex-basis: 55%;
    }
    & > :nth-child(2) {
      flex-basis: 35%;
    }
    & > :nth-child(3) {
      flex-basis: 10%;
      text-align: right;
    }
  }

  &__list-wrapper {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $color-primary $border-default-color;
    min-height: 358px; // 400px - 42px header height

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: $border-default-color;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 3px;
    }
  }
}
