.TrainingMaterialItem {
  margin-bottom: 16px;
  
  .thumbnail-container {
    width: 100%;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    
    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }
    
    .play-button-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.3s ease;
      
      .anticon {
        font-size: 24px;
        color: white;
      }
    }
    
    &:hover {
      .thumbnail {
        transform: scale(1.05);
      }
      
      .play-button-overlay {
        opacity: 1;
      }
    }
  }
  
  .material-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    .material-title {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      
      .material-icon {
        font-size: 20px;
        
        &.video {
          color: #1890ff;
        }
        
        &.article {
          color: #52c41a;
        }
      }
    }
    
    .material-info {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    
    .material-description {
      margin-top: 4px;
    }
  }
  
  .material-actions {
    display: flex;
    justify-content: flex-end;
    
    .preview-icon,
    .edit-icon,
    .delete-icon {
      padding: 8px;
      font-size: 16px;
      cursor: pointer;
      transition: color 0.3s;
    }
    
    .preview-icon {
      color: #722ed1;
      
      &:hover {
        color: #9254de;
      }
    }
    
    .edit-icon {
      color: #1890ff;
      
      &:hover {
        color: #40a9ff;
      }
    }
    
    .delete-icon {
      color: #ff4d4f;
      
      &:hover {
        color: #ff7875;
      }
    }
  }
}

// Video preview modal
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
} 